import React from 'react'
import { useSelector } from 'react-redux'
import { Carousel } from 'react-responsive-carousel'

//Components
import Nav from './components/Nav'
import Budget from './components/Budget'


//Global fuction
import Footer from '../../components/Footer'

import { useNavigate } from "react-router-dom";


import M from '../../assets/images/malaM.png'
import P from '../../assets/images/malaP.png'
import G  from '../../assets/images/malaG.png'



function Home(props) {

   const user = useSelector(state => state.Auth.user)
   const navigate = useNavigate();

   return (
      <div className='w-full'>
         <div className='bg-black'>
            <Nav
               user={user}
               navegar={(value) => navigate(value)}
            />
         </div>

         <Budget
            
         />

         <div className='max-w-6xl m-10 mx-auto px-5 hidden sm:block'>
            <p className='mb-0 text-[20px] font-bold mb-5'>Não pague caro em malas de viagem, alugue e pague menos!</p>
            <div className='flex justify-between flex-wrap items-center'>

               <div className="flex flex-col items-center justify-evenly h-96 w-60 shadow-2xl rounded-lg">
                  <img src={require('../../assets/images/malaM.png')} alt='alt' style={{ height: 200 }} />
                  <div className='flex flex-col items-center' >
                     <p>Mala tamanho: <strong>M</strong></p>
                     <strong>R$ 11,00</strong>
                     <p className='text-[9px]' >Aluguel a partir de 5 diárias</p>
                  </div>

                  <button className='bg-primary h-[40px] w-[200px] rounded-2xl' onClick={() => navigate('/')}>
                     <p className='text-white font-bold font-[20px]' href='/'>Alugar</p>
                  </button>
               </div>

               <div className="flex flex-col items-center justify-evenly h-96 w-60 shadow-2xl rounded-lg">
                  <img src={require('../../assets/images/malaG.png')} alt='alt' style={{ height: 200 }} />

                  <div className='flex flex-col items-center' >
                     <p>Mala tamanho: <strong>G</strong></p>
                     <strong>R$ 12,00</strong>
                     <p className='text-[9px]' >Aluguel a partir de 5 diárias</p>
                  </div>

                  <button className='bg-primary h-[40px] w-[200px] rounded-2xl' onClick={() => navigate('/')}>
                     <p className='text-white font-bold font-[20px]' href='/'>Alugar</p>
                  </button>

               </div>

               <div className="flex flex-col items-center justify-evenly h-96 w-60 shadow-2xl rounded-lg">
                  <img src={require('../../assets/images/malaP.png')} alt='alt' style={{ height: 200 }} />

                  <div className='flex flex-col items-center' >
                     <p>Mala tamanho: <strong>P</strong></p>
                     <strong>R$ 9,00</strong>
                     <p className='text-[9px]' >Aluguel a partir de 5 diárias</p>
                  </div>

                  <button className='bg-primary h-[40px] w-[200px] rounded-2xl' onClick={() => navigate('/')}>
                     <p className='text-white font-bold font-[20px]' href='/'>Alugar</p>
                  </button>
               </div>
            </div>
         </div>



         <div className='max-w-6xl m-10 mx-auto px-5 sm:hidden block'>
            <p className='mb-0 text-[25px] font-bold'>Não pague caro em malas de viagem, alugue e pague menos</p>
            <Carousel
               showArrows={true}
               showIndicators={true}
               showThumbs={false}
               showStatus={false}
               swipeable={true}
               infiniteLoop
               autoPlay
               emulateTouch
               dynamicHeight={true}
               interval={4000}
            >

               <div className="flex flex-col items-center justify-evenly h-[500px] rounded-lg " >
                  <div
                     style={{
                        backgroundImage: `url(${M})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: '200px',
                        width: '100px'
                     }}
                  />
                  <div className='flex flex-col items-center' >
                     <p>Mala tamanho: <strong>M</strong></p>
                     <strong>R$ 11,00</strong>
                     <p className='text-[9px]' >Aluguel a partir de 5 diárias</p>
                  </div>

                  <button className='bg-primary h-[40px] w-[200px] rounded-2xl' onClick={() => navigate('/')}>
                     <p className='text-white font-bold font-[20px]' href='/'>Alugar</p>
                  </button>
               </div>

               <div className="flex flex-col items-center justify-evenly h-[500px] rounded-lg">
                  <div
                     style={{
                        backgroundImage: `url(${G})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: '200px',
                        width: '100px'
                     }}
                  />

                  <div className='flex flex-col items-center' >
                     <p>Mala tamanho: <strong>G</strong></p>
                     <strong>R$ 12,00</strong>
                     <p className='text-[9px]' >Aluguel a partir de 5 diárias</p>
                  </div>

                  <button className='bg-primary h-[40px] w-[200px] rounded-2xl' onClick={() => navigate('/')}>
                     <p className='text-white font-bold font-[20px]' href='/'>Alugar</p>
                  </button>

               </div>

               <div className="flex flex-col items-center justify-evenly h-[500px] rounded-lg">
                  <div
                     style={{
                        backgroundImage: `url(${P})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: '200px',
                        width: '100px'
                     }}
                  />

                  <div className='flex flex-col items-center' >
                     <p>Mala tamanho: <strong>P</strong></p>
                     <strong>R$ 9,00</strong>
                     <p className='text-[9px]' >Aluguel a partir de 5 diárias</p>
                  </div>

                  <button className='bg-primary h-[40px] w-[200px] rounded-2xl' onClick={() => navigate('/')}>
                     <p className='text-white font-bold font-[20px]' href='/'>Alugar</p>
                  </button>
               </div>

            </Carousel>
         </div>

         <Footer />
      </div>
   )
}

export default Home
