/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import 'dayjs/locale/pt';

import 'moment/locale/pt-br'


import { HelpersBookings } from '../../../../helpers';
const helpersBookings = new HelpersBookings()

function SelectDate({ branchs, simulate, setSimulate, user }) {
   const [checkin, setCheckin] = useState(null)
   const [checkinErro, setCheckinErro] = useState(false)
   const [checkout, setCheckout] = useState(null)
   const [checkoutErro, setCheckoutErro] = useState(false)
   const [malaP, setMalaP] = useState("")
   const [malaM, setMalaM] = useState("")
   const [malaG, setMalaG] = useState("")
   const [malaErro, setMalaErro] = useState(false)
   const [branch, setBranch] = useState("")
   const [branchErro, setBranchErro] = useState(false)
   const [loadingSimulate, setLoadingSimulate] = React.useState(false);
   const [selectDateCollectModal, setSelectDateCollectModal] = useState(false)
   const [selectDateDeliveryModal, setSelectDateDeliveryModal] = useState(false)
   const [selectBranchModal, setSelectBranchModal] = useState(false)
   const [selectQtdModal, setSelectQtdModal] = useState(false)
   const [showFilter, setShowFilter] = React.useState(false);
   const [showErroBudget, setShowErroBudget] = React.useState(false);

   return (
      <div className='-mt-60 max-w-6xl mx-auto hidden sm:block'>
         <div className='sm:block hidden' >
            <p className='text-white text-[40px] font-bold'>#blackmalug</p>
            <p className='text-white text-[40px] font-bold'>Alugue suas malas com 20%OFF! <br />a partir de R$7,90</p>
            <p className='text-white text-[15px] font-normal'>Preencha abaixo as informações necessárias para seu orçamento <br />*Promoção válida pra todo site!</p>
         </div>
      </div>
   )
}

export default SelectDate


