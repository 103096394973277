import React from "react";
import ReactStars from "react-rating-stars-component";
import { Carousel } from 'react-responsive-carousel'

//Images
import rev1 from '../../../../assets/reviews/IMG_1.jpeg'
import rev2 from '../../../../assets/reviews/IMG_2.jpeg'
import rev3 from '../../../../assets/reviews/IMG_3.jpeg'


const Reviews = () => {
    return (
        <>
            <div className='max-w-6xl m-20 mx-auto px-5 hidden sm:block'>
                <p className='mb-0 text-[25px] font-bold'>Facilidade e comodidade ao alugar malas</p>
                <h6 className='mt-0 font-medium mb-10'>Veja o depoimento de alguns clientes:</h6>
                <div className='flex justify-between flex-wrap items-center'>
                    <div style={{ width: '212px' }} className="flex flex-col items-center">
                        <div style={{
                            backgroundImage: `url(${rev1})`,
                            backgroundSize: 'contain   ',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            height: '280px',
                            width: '212px',
                        }} className='flex pt-40 px-5 rounded-xl' />
                        <ReactStars
                            count={5}
                            onChange={{}}
                            size={24}
                            activeColor="#ffd700"
                            value={5}
                            isHalf={true}
                            edit={false}
                        />
                        <p className='font-medium text-black text-center'>
                            Alugar com a malug é sempre fácil, preços acessíveis e um bom atendimento...
                        </p>

                    </div>
                    <div style={{ width: '212px' }} className="flex flex-col items-center">
                        <div style={{
                            backgroundImage: `url(${rev2})`,
                            backgroundSize: 'contain   ',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            height: '280px',
                            width: '212px',
                        }} className='flex pt-40 px-5 rounded-xl' />
                        <ReactStars
                            count={5}
                            onChange={{}}
                            size={24}
                            activeColor="#ffd700"
                            value={5}
                            isHalf={true}
                            edit={false}
                        />
                        <p className='font-medium text-black text-center'>
                            O que mais mais gosto da Malug é que eles vem entregar e receber na minha casa...
                        </p>
                    </div>

                    <div style={{ width: '212px' }} className="flex flex-col items-center">
                        <div style={{
                            backgroundImage: `url(${rev3})`,
                            backgroundSize: 'contain   ',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            height: '280px',
                            width: '212px',
                        }} className='flex pt-40 px-5 rounded-xl' />
                        <ReactStars
                            count={5}
                            onChange={{}}
                            size={24}
                            activeColor="#ffd700"
                            value={5}
                            isHalf={true}
                            edit={false}
                        />
                        <p className='font-medium text-black text-center'>
                            A praticidade é incrivél, é só fazer a reserva pelo site e esperar a mala chegar até a gente...
                        </p>
                    </div>

                </div>
            </div>



            <div className='max-w-6xl m-20 mx-auto px-5 sm:hidden block'>
                <p className='mb-0 text-[25px] font-bold'>Facilidade e comodidade ao alugar malas</p>
                <h6 className='mt-0 font-medium mb-10'>Veja o depoimento de alguns clientes:</h6>
                <Carousel
                    showArrows={false}
                    showIndicators={false}
                    showThumbs={false}
                    showStatus={false}
                    swipeable={true}
                    infiniteLoop
                    autoPlay
                    emulateTouch
                    dynamicHeight={true}
                    interval={5000}
                >
                    <div
                        style={{
                            backgroundImage: `url(${rev1})`,
                            backgroundSize: 'cover   ',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            height: '390px',
                        }}
                        className='flex px-5 rounded-xl flex flex-col justify-end items-center'
                    >
                        <ReactStars
                            count={5}
                            onChange={{}}
                            size={24}
                            activeColor="#ffd700"
                            value={5}
                            isHalf={true}
                            edit={false}
                        />
                        <p className='text-white text-start pl-5 md:pl-20 py-3'>
                            Alugar com a malug é sempre fácil, preços acessíveis e um bom atendimento...
                        </p>
                    </div>
                    <div
                        style={{
                            backgroundImage: `url(${rev2})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            height: '390px',

                        }}
                        className='flex px-5 rounded-xl flex flex-col justify-end items-center'
                    >

                        <ReactStars
                            count={5}
                            onChange={{}}
                            size={24}
                            activeColor="#ffd700"
                            value={5}
                            isHalf={true}
                            edit={false}
                        />
                        <p className='text-white text-start pl-5 md:pl-20 py-3'>
                            O que mais mais gosto da Malug é que eles vem entregar e receber na minha casa...
                        </p>
                    </div>
                    <div
                        style={{
                            backgroundImage: `url(${rev3})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            height: '390px',
                        }}
                        className='flex px-5 rounded-xl flex flex-col justify-end items-center'
                    >
                        <ReactStars
                            count={5}
                            onChange={{}}
                            size={24}
                            activeColor="#ffd700"
                            value={5}
                            isHalf={true}
                            edit={false}
                        />
                        <p className='text-white text-start pl-5 md:pl-20 py-3'>
                            A praticidade é incrivél, é só fazer a reserva pelo site e esperar a mala chegar até a gente...
                        </p>
                    </div>
                </Carousel>
            </div>

        </>

    )
}

export default Reviews;